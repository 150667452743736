import isDevEnv from './isDevEnv';
import root from 'window-or-global';

const _quiet = !isDevEnv;
export const bridgeUrl = isDevEnv ? `http://0-0-0-0.nip.io:10000/` : `https://api-${root.location.host}/`;

const _log = (msg) => {
  !_quiet && console.log("DataFetcher", msg, Date.now());
};

const _fetch = (endpoint, body = {}, signal = null) => {
  let opts = {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    }
  };
  if(signal) opts.signal = signal;

  return fetch(`${bridgeUrl}${endpoint}`, opts)
    .then((r) => r.json())
    .catch((e) => console.error(e));
};

export const fetching = (endpoint, body) => {
  _log(`fetch -> ${endpoint} with ${JSON.stringify(body)}`);
  return _fetch(endpoint, body);
};

export const abortableFetching = (endpoint, body) => {
  _log(`abortableFetch -> ${endpoint} with ${JSON.stringify(body)}`);

  const controller = new AbortController();
  const { signal } = controller;
  return {
    abort: () => controller.abort(),
    ready: _fetch(endpoint, body, signal)
  }
};
