import { Text, MarkupText, useText, intl } from 'preact-i18n/src';
import root from 'window-or-global';
import _get from 'lodash/get';

const _t = (prefix, id, txt, fields = {}, noPrefix = false) => {
  const lookup = `${noPrefix ? '' : `${prefix}.`}${id}`;
  const rawText = _get(root.__langDefinitions, lookup);

  if(/<[a-z/][\s\S]*>/i.test(rawText))
    return (<MarkupText id={lookup} fields={fields}>{txt}</MarkupText>);

  return (<Text id={lookup} fields={fields}>{txt}</Text>);
};

const _ph = (prefix, id, txt, fields = {}, noPrefix = false) => {
  let _id = noPrefix ? id : `${prefix}.${id}`;
  let { text } = useText({ text: <Text id={_id} fields={fields}>{txt}</Text> });
  return text || txt;
};

export const generateLocalization = function(prefix) {
  return [
    (...args) => _t(prefix, ...args),
    (...args) => _ph(prefix, ...args)
  ]
}
