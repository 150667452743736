import validDataUrl from 'valid-data-url';

export default function(file) {
  return new Promise((resolve, reject) => {
    if(typeof file === "string" && validDataUrl(file)) return resolve(file);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  })
}
