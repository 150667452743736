import { h } from 'preact';
import { Router } from 'preact-router';

import { version } from './../../package.json';

import Header from './header';
import Footer from './footer';
import { IntlProvider } from 'preact-i18n/src';

import StatusAlert from 'react-status-alert';
import 'react-status-alert/dist/status-alert.css'
import './../global.css';
import root from 'window-or-global';

import definition_de from './../locales/de.json';
import definition_pl from './../locales/pl.json';

const definitions = {
  de: definition_de,
  pl: definition_pl
};

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import RegisterCompany from '../routes/company/register';
import RegisterCompanyThx from '../routes/company/register/thx';
import ErrorView from '../routes/error';
import PwdResetConfirmation from '../routes/pwdresetconfirmation'

import SuperAdmin from '../routes/superadmin';
import FirmaRouter from '../routes/firma';

import Cookie from 'js-cookie';
import tld from '../utils/tld';

if(!Cookie.get('lang')) Cookie.set('lang', 'pl', {domain: `.${tld}`, expires: 356});
root.__langDefinitions = definitions[Cookie.get('lang')];

const App = () => (
  <IntlProvider definition={root.__langDefinitions}>
    <div id="app" v={version}>
      <Header />
      <StatusAlert />
      <Router>
        <Home path="/" />
        <PwdResetConfirmation path="/pwdresetconfirmation/:token" />
        <RegisterCompany path="/zamow" />
        <RegisterCompanyThx path="/zamow-thx" />
        <SuperAdmin path="/superadmin/:rest*" />
        <ErrorView path="/error/:code*" />
        <FirmaRouter path="/:companydomain/:rest*" />
      </Router>
      <Footer />
    </div>
  </IntlProvider>
)

export default App;
