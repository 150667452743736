import { h } from 'preact';
import { route } from 'preact-router';
import { Text, useText } from 'preact-i18n/src';
import style from './style.css';
import { generateLocalization } from './../../../../utils/generateLocalization';

import LinkedButtonStyle from './../../../../components/linked-button/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

const [_t, _ph] = generateLocalization("company.register.thx");

const CompanyRegisterThx = (props) => {
  return (
    <div className={`mainSpan ${style.companyregisterthx}`}>
      <h1>{_t("title", "THX")}</h1>
      <FontAwesomeIcon className="fa-6x fa-beat fa-beat-1x" style={{color: "var(--bg-color-secondary)"}} icon={faHeart} />
      <p>&nbsp;</p>
      <p>{_t("text", "text")}</p>
      <a className={LinkedButtonStyle.linkedbutton} href="https://pibip.pl">
        {_t("backToPibip")}
      </a>
    </div>
  );
};

export default CompanyRegisterThx;
