import { h, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { route } from 'preact-router';
import style from './style.css';
import './../../../style/form.css';
import './../../../style/indicators.css';
import { fetching } from './../../../utils/fetcher';
import getBase64 from './../../../utils/getBase64';
import { generateLocalization } from './../../../utils/generateLocalization';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import { StatusAlertService } from 'react-status-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faTrash, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import root from 'window-or-global';

import { availableCountries } from './../../../config';

const [_t, _ph] = generateLocalization("company.register");

let _data = {};
let _alreadyCheckedEmails = {};

const _checkIVsWithAppliedData = (d) => {
  let keys = Object.keys(d);
  let returny = {};
  for(let key of keys)
    returny[key] = _data[key] || d[key];

  return returny;
};

const CompanyRegister = (props) => {
  const isPendingCompanyEdit = Boolean(props.path == "/superadmin/pending-company-edit/:id" && props.id);
  const isCompanyEdit = Boolean(props.path == "/superadmin/company-edit/:id" && props.id);
  const [pageIndex, setPage] = useState(0);
  const [isSubmitting, setSubmitting] = useState(false);
  const [alertId, setAlert] = useState('');

  const [isFetching, setFetching] = useState(isPendingCompanyEdit || isCompanyEdit);

  if(isFetching){
    useEffect(() => {
      if(!isPendingCompanyEdit && !isCompanyEdit) return;
      fetching(`superadmin/${isPendingCompanyEdit ? "pending-" : ""}company-edit/view`, {_id: props.id})
        .then(data => {
          if(!data.success){
            let aid = StatusAlertService.showError(
              _t(`RESPONSES.${data.msg}`, data.msg, _data ? _data : {}, true)
            );
            setAlert(aid);
            return route("/superadmin");
          }

          _data = data.data;

          // add email to OK status :)
          _alreadyCheckedEmails[_data.email] = true;
          _data.managers.map(m => {_alreadyCheckedEmails[m.email] = true});

          setFetching(false);
        })
    }, []);
    return (
      <div className={`mainSpan`}>
        <FontAwesomeIcon className="fa-2x fa-spin" icon={faSpinner} />
      </div>
    )
  }

  const goBack = (e) => {
    setPage(pageIndex-1);
  };

  const pages = [
    (<Formik
      key="crf1"
      initialValues={_checkIVsWithAppliedData({
        firstname: null,
        lastname: null,
        companyname: null,
        vatid: null,
        street: null,
        housenumber: null,
        zipcode: null,
        city: null,
        email: null,
        companyphone: null,
        country: "POL",
        tos: false,
        privacy: false,
        privacy2: false,
        billing: false
      })}

      validate={(values) => {
        const errors = {};
        if(!values.email) return errors;
        if(_alreadyCheckedEmails[values.email] != undefined) {
          if(_alreadyCheckedEmails[values.email]) return errors;

          errors.email = _t(`RESPONSES.EMAIL_USAGE_BAD`, "bad email", {}, true);
          return errors;
        }
        return fetching("check-email-usage", {email: values.email})
          .then(data => {
            _alreadyCheckedEmails[values.email] = data.success;
            if(data.success == false)
              errors.email = _t(`RESPONSES.EMAIL_USAGE_BAD`, "bad email", {}, true);
            return errors;
          });
      }}

      onSubmit={(values) => {
        _data = Object.assign(_data, values);
        setPage(pageIndex+1);
      }}
    >
      <Form key="crff1">
        <div className="formElement">
          <label>
            <span>{_t("form.firstname", "Firstname")}*</span>
            <Field type="text" name="firstname" required/>
          </label>
        </div>
        <div className="formElement">
          <label>
            <span>{_t("form.lastname", "Lastname")}*</span>
            <Field type="text" name="lastname" required />
          </label>
        </div>
        <div className="formElement">
          <label>
            <span>{_t("form.companyname", "Name of your company")}*</span>
            <Field type="text" name="companyname" required />
          </label>
        </div>
        <div className="formElement">
          <label>
            <span>{_t("form.vatid", "VAT-Id of your company")}*</span>
            <Field type="text" name="vatid" required />
          </label>
        </div>
        <div className="formElement asRow">
          <label className="flex-width-3-4">
            <span>{_t("form.street", "Street")}*</span>
            <Field type="text" name="street" required />
          </label>
          <label className="flex-width-1-4">
            <span>{_t("form.housenumber", "Housenumber")}*</span>
            <Field type="text" name="housenumber" required />
          </label>
        </div>
        <div className="formElement asRow">
          <label className="flex-width-1-4">
            <span>{_t("form.zipcode", "Zip Code")}*</span>
            <Field type="text" name="zipcode" required />
          </label>
          <label className="flex-width-3-4">
            <span>{_t("form.city", "City")}*</span>
            <Field type="text" name="city" required />
          </label>
        </div>
        <div className="formElement">
          <label>
            <span>{_t("form.email", "Email")}*</span>
            <Field type="email" name="email" required />
          </label>
          <ErrorMessage name="email" className="err" component="div" />
        </div>
        <div className="formElement">
          <label>
            <span>{_t("form.companyphone", "Phonenumber of company")}*</span>
            <Field type="tel" name="companyphone" required />
          </label>
        </div>
        <div className="formElement">
          <label>
            <span>{_t("form.country", "Country")}*</span>
            <Field as="select" name="country">
              {availableCountries.map((c, idx) => (<option selected={idx == 0} value={c} label={_ph(`countries.${c}`, c, {}, true)} />))}
            </Field>
          </label>
        </div>

        <div className="formElement asRow">
          <button type="submit" disabled={isSubmitting}>
            {_t("form.goOn", "Go On!")}
          </button>
        </div>
      </Form>
    </Formik>),
    (<Formik
      key="crf2"
      initialValues={_checkIVsWithAppliedData({
        domainname: null,
        submissiontype: "anonymous",
        rulesdoc: null,
        logo: null,
      })}

      validate={(values) => {
        const errors = {};
        let pattern = /^[a-z0-9-]*$/;

        if(!pattern.test(values.domainname))
          errors.domainname = _t("form.domainnameerror", "only lowercase numbers an minus allowed");

        return errors;
      }}

      onSubmit={(values) => {
        let submitPromiseCounter = 0;
        const _done = () => {
          if(submitPromiseCounter > 0) return;
          setSubmitting(false);
          return setPage(pageIndex+1);
        };

        if(isSubmitting) return;
        setSubmitting(true);
        _data = Object.assign(_data, values);
        if(values.rulesdoc) submitPromiseCounter++;
        if(values.logo) submitPromiseCounter++;

        if(submitPromiseCounter == 0) return _done();

        if(values.rulesdoc)
          getBase64(values.rulesdoc)
            .then(x => {
              _data.rulesdoc = x;
              submitPromiseCounter--;
              return _done()
            })
            .catch(e => {
              setSubmitting(false);
            });

        if(values.logo)
          getBase64(values.logo)
            .then(x => {
              _data.logo = x;
              submitPromiseCounter--;
              return _done()
            })
            .catch(e => {
              setSubmitting(false);
            });
      }}

      render={({ values, handleSubmit, setFieldValue }) => {
        return (<Form key="crff2">
          <div className="formElement">
            <label>
              <div style={{display: "flex", flexDirection: "row"}}>
                <span>{_t("form.domainname", "Domainname")}*</span>
                <Popup closeOnDocumentClick on={["hover", "click"]}trigger={<p className={style.infoiHolder}>&nbsp;<FontAwesomeIcon icon={faCircleQuestion} /></p>} position={['left center', 'bottom center']}>
                  <div>{_t("form.clouds.domainname", "domainname-cloud")}</div>
                  <div><i>{_t("faqpopupsuffix", "faqpopupsuffix", {}, true)}</i></div>
                </Popup>
              </div>
              <Field type="text" name="domainname" required />
            </label>
            <ErrorMessage name="domainname" className="err" component="div" />
          </div>
          <div className="formElement">
            <label style={{flexDirection: "row"}}>
              <span>{_t("form.submissiontype", "Submissiontype")}*</span>
              <Popup closeOnDocumentClick on={["hover", "click"]}trigger={<p className={style.infoiHolder}>&nbsp;<FontAwesomeIcon icon={faCircleQuestion} /></p>} position={['left center', 'bottom center']}>
                <div>{_t("form.clouds.submissiontype", "submissiontype-cloud")}</div>
                <div><i>{_t("faqpopupsuffix", "faqpopupsuffix", {}, true)}</i></div>
              </Popup>
            </label>
            <div role="group">
              <label className="asRow">
                <Field type="radio" name="submissiontype" value="anonymous" />
                <p className={style.radioboxLabelText}>{_t("form.submissiontypes.0", "0")}</p>
                <Popup closeOnDocumentClick on={["hover", "click"]}trigger={<p className={style.infoiHolder}>&nbsp;<FontAwesomeIcon icon={faCircleQuestion} /></p>} position={['left center', 'bottom center']}>
                  <div>{_t("form.submissiontypesinfo.0", "0")}</div>
                  <div><i>{_t("faqpopupsuffix", "faqpopupsuffix", {}, true)}</i></div>
                </Popup>
              </label>
              <label className="asRow">
                <Field type="radio" name="submissiontype" value="confidential" />
                <p className={style.radioboxLabelText}>{_t("form.submissiontypes.1", "1")}</p>
                <Popup closeOnDocumentClick on={["hover", "click"]}trigger={<p className={style.infoiHolder}>&nbsp;<FontAwesomeIcon icon={faCircleQuestion} /></p>} position={['left center', 'bottom center']}>
                  <div>{_t("form.submissiontypesinfo.1", "1")}</div>
                  <div><i>{_t("faqpopupsuffix", "faqpopupsuffix", {}, true)}</i></div>
                </Popup>
              </label>
              <label className="asRow">
                <Field type="radio" name="submissiontype" value="both" />
                <p className={style.radioboxLabelText}>{_t("form.submissiontypes.2", "2")}</p>
                <Popup closeOnDocumentClick on={["hover", "click"]}trigger={<p className={style.infoiHolder}>&nbsp;<FontAwesomeIcon icon={faCircleQuestion} /></p>} position={['left center', 'bottom center']}>
                  <div>{_t("form.submissiontypesinfo.2", "2")}</div>
                  <div><i>{_t("faqpopupsuffix", "faqpopupsuffix", {}, true)}</i></div>
                </Popup>
              </label>
            </div>
          </div>
          <div className="formElement">
            <label style={{flexDirection: "row"}}>
              <span>{_t("form.rulesdoc", "Document with Rules")}</span>
              <Popup closeOnDocumentClick on={["hover", "click"]}trigger={<p className={style.infoiHolder}>&nbsp;<FontAwesomeIcon icon={faCircleQuestion} /></p>} position={['left center', 'bottom center']}>
                <div>{_t("form.clouds.documents", "documents")}</div>
                <div><i>{_t("faqpopupsuffix", "faqpopupsuffix", {}, true)}</i></div>
              </Popup>
            </label>
            <input id="rulesdoc" name="rulesdoc" accept="application/pdf" type="file" onChange={(event) => {
              setFieldValue("rulesdoc", event.currentTarget.files[0]);
            }} />
          </div>
          <div className="formElement">
            <label>
              <span>{_t("form.logo", "Logo")}</span>
              <input id="logo" name="logo" accept="image/jpg,image/png,image/jpeg" type="file" onChange={(event) => {
                setFieldValue("logo", event.currentTarget.files[0]);
              }} />
            </label>
          </div>
          <div className="formElement asRow">
            <button type="button" onClick={goBack}>
              {_t("form.back", "< back")}
            </button>
            <button type="submit">
              {_t("form.goOn", "Go On!")}
            </button>
          </div>
        </Form>
      )}}
    />),
    (<Formik
      key="crf3"
      initialValues={_checkIVsWithAppliedData({
        managers: [],
      })}
      validate={(values) => {
        let errors = {};
        if(values.managers.length == 0)
          errors.managers = _t("form.managers.toless", "to less managers, u need at least one");

        return Promise.all(values.managers.map((m, idx) => {
          if(!m.email) return true;
          if(_alreadyCheckedEmails[m.email] != undefined){
            if(_alreadyCheckedEmails[m.email]) return true;
            errors.managers = _t(`RESPONSES.EMAIL_USAGE_BAD_COMPANY_MANAGERS`, "bad email", {count: idx+1}, true);
            return true;
          }
          return fetching("check-email-usage", {email: m.email})
            .then(data => {
              _alreadyCheckedEmails[m.email] = data.success;
              if(data.success == false)
                errors.managers = _t(`RESPONSES.EMAIL_USAGE_BAD_COMPANY_MANAGERS`, "bad email", {count: idx+1}, true);
              return true;
            })
            .catch(e => true)
        }))
        .then(() => errors)
      }}
      onSubmit={(values) => {
        values.managers = values.managers.map(m => { delete m.key; return m });
        _data = Object.assign(_data, values);
        setPage(pageIndex+1);
      }}
      render={({values}) => (
        <Form key="crff3">
          <FieldArray
            name="managers"
            render={arrayHelpers => (
              <Fragment>
                {values.managers && values.managers.length > 0 && (
                   values.managers.map((manager, index) => (
                    <div key={manager.key}>
                      <div className="formElement asSeperator">
                        <div className="formElement asRow headerRow">
                          <h3 className="flex-width-3-4">{_t("form.managers.counter", `Manager #${index+1}`, {count: index+1})}</h3>
                          <div
                            className="flex-width-1-4"
                            style={{"justify-content": "flex-end"}}
                            onClick={() => arrayHelpers.remove(index)}>
                              <FontAwesomeIcon icon={faTrash} />
                          </div>
                        </div>
                        <div className="formElement">
                          <label>
                            <span>{_t("form.firstname", "Firstname")}*</span>
                            <Field
                              id={`mln-${manager.key}-firstname`}
                              name={`managers.${index}.firstname`}
                              type="text"
                              required
                            />
                          </label>
                        </div>
                        <div className="formElement">
                          <label>
                            <span>{_t("form.lastname", "Lastname")}*</span>
                            <Field
                              id={`mln-${manager.key}-lastname`}
                              name={`managers.${index}.lastname`}
                              type="text"
                              required
                            />
                          </label>
                        </div>
                        <div className="formElement">
                          <label>
                            <div style={{display: "flex", flexDirection: "row"}}>
                              <span>{_t("form.managers.external", "external")}</span>
                              <Popup closeOnDocumentClick on={["hover", "click"]}trigger={<p className={style.infoiHolder}>&nbsp;<FontAwesomeIcon icon={faCircleQuestion} /></p>} position={['left center', 'bottom center']}>
                                <div>{_t("form.managers.clouds.external", "managers-cloud")}</div>
                                <div><i>{_t("faqpopupsuffix", "faqpopupsuffix", {}, true)}</i></div>
                              </Popup>
                            </div>
                            <Field
                              id={`mln-${manager.key}-external`}
                              name={`managers.${index}.external`}
                              type="text"
                            />
                          </label>
                        </div>
                        <div className="formElement asRow">
                          <label className="flex-width-3-4">
                            <span>{_t("form.street", "Street")}*</span>
                            <Field
                              id={`mln-${manager.key}-street`}
                              name={`managers.${index}.street`}
                              type="text"
                              required
                            />
                          </label>
                          <label className="flex-width-1-4">
                            <span>{_t("form.housenumber", "Housenumber")}*</span>
                            <Field
                              id={`mln-${manager.key}-housenumber`}
                              name={`managers.${index}.housenumber`}
                              type="text"
                              required
                            />
                          </label>
                        </div>
                        <div className="formElement asRow">
                          <label className="flex-width-1-4">
                            <span>{_t("form.zipcode", "Zip Code")}*</span>
                            <Field
                              id={`mln-${manager.key}-zipcode`}
                              name={`managers.${index}.zipcode`}
                              type="text"
                              required
                            />
                          </label>
                          <label className="flex-width-3-4">
                            <span>{_t("form.city", "City")}*</span>
                            <Field
                              id={`mln-${manager.key}-city`}
                              name={`managers.${index}.city`}
                              type="text"
                              required
                            />
                          </label>
                        </div>
                        <div className="formElement">
                          <label>
                            <span>{_t("form.managers.email", "Email")}*</span>
                            <Field
                              id={`mln-${manager.key}-email`}
                              name={`managers.${index}.email`}
                              type="email"
                              required
                            />
                          </label>
                        </div>
                        <div className="formElement">
                          <label>
                            <span>{_t("form.managers.contactphone", "Phonenumber of company")}*</span>
                            <Field
                              id={`mln-${manager.key}-contactphone`}
                              name={`managers.${index}.contactphone`}
                              type="tel"
                              required
                            />
                          </label>
                        </div>
                        <div className="formElement">
                          <label>
                            <span>{_t("form.country", "Country")}*</span>
                            <Field as="select" id={`mln-${manager.key}-country`} name={`managers.${index}.country`}>
                              {availableCountries.map((c, idx) => (<option selected={idx == 0} value={c} label={_ph(`countries.${c}`, c, {}, true)} />))}
                            </Field>
                          </label>
                        </div>
                      </div>
                    </div>
                   ))
                 )}

                 {values.managers.length < 3 && (
                   <div className="formElement">
                     <button type="button" onClick={() => arrayHelpers.push({key: Date.now()})}>
                       {_t(`form.managers.addNew${values.managers.length > 0 ? 'More':''}`, "new manager")}
                     </button>
                   </div>
                 )}
               </Fragment>
            )}
          />
          <div className="formElement">
            <ErrorMessage name="managers" className="err" component="div" />
          </div>
          <div className="formElement asRow">
            <button type="button" onClick={goBack}>
              {_t("form.back", "< back")}
            </button>
            <button type="submit">
              {_t("form.goOn", "Go On!")}
            </button>
          </div>
        </Form>
      )}
      />
    ),
    (<Formik
      key="crf4"
      initialValues={_checkIVsWithAppliedData({
        tos: false,
        privacy: false,
        privacy2: false,
        billing: false
      })}

      validate={(values) => {
        const errors = {};
        return errors;
      }}

      onSubmit={(values, { resetForm }) => {
        if(isSubmitting) return;
        setSubmitting(true);
        _data = Object.assign(_data, values);
        let u = "company-register";
        if(isPendingCompanyEdit) u = "superadmin/pending-company-edit/save";
        if(isCompanyEdit) u = "superadmin/company-edit/save";
        fetching(u , _data)
          .then(data => {
            let aid = StatusAlertService.showAlert(
              _t(`RESPONSES.${data.msg}`, data.msg, _data, true),
              data.success ? "success" : "error",
            );
            setAlert(aid);

            if(!data.success)
              return setSubmitting(false);

            resetForm();
            route(isPendingCompanyEdit || isCompanyEdit ? '/superadmin' : '/zamow-thx');
          })
      }}
    >
      {(!isPendingCompanyEdit && !isCompanyEdit) && (
        <Form key="crff4">
          <div className="formElement">
            <label className="asRow">
              <Field type="checkbox" name="tos" required />
              <span className="asRow">{_t("form.tos", "ToS")}*</span>
            </label>
          </div>
          <div className="formElement">
            <label className="asRow">
              <Field type="checkbox" name="privacy" required />
              <span className="asRow">{_t("form.privacy", "Privacy")}*</span>
            </label>
          </div>
          <div className="formElement">
            <label className="asRow">
              <Field type="checkbox" name="privacy2" required />
              <span className="asRow">{_t("form.privacy2", "Privacy2")}*</span>
            </label>
          </div>
          <div className="formElement">
            <label className="asRow">
              <Field type="checkbox" name="billing" required />
              <span className="asRow">{_t("form.billing", "Billing")}*</span>
            </label>
          </div>
          <div className="formElement asRow">
            <p><i>{_t("form.legaltext", "legaltext")}</i></p>
          </div>
          <div className="formElement asRow">
            <button type="button" onClick={goBack}>
              {_t("form.back", "< back")}
            </button>
            <button type="submit">
              {_t("form.submit", "Order!")}
            </button>
          </div>
        </Form>
      )}

      {(isPendingCompanyEdit || isCompanyEdit) && (
        <Form key="crff4">
          <div className="formElement asRow">
            <h3>{_t('superadmin.pendingCompanyEdit.saveFinally', "save?", {}, true)}</h3>
          </div>
          <div className="formElement asRow">
            <button type="button" onClick={goBack}>
              {_t("form.back", "< back")}
            </button>
            <button type="submit">
              {_t("superadmin.pendingCompanyEdit.saveFinallyButton", "SAVE!", {}, true)}
            </button>
          </div>
        </Form>
      )}

    </Formik>),
  ];

  const maxPages = pages.length;
  return (
    <div className={`mainSpan ${style.companyRegister}`}>
      <h1>{isPendingCompanyEdit
          ?
          _t("superadmin.pendingCompanyEdit.title", "PendingCompany Edit", {}, true)
          :
          (isCompanyEdit
            ?
            _t("superadmin.companyEdit.title", "Company Edit", {companyname: _data.companyname}, true)
            :
            _t("title", "Company Register")
          )
        }</h1>
      {((!isPendingCompanyEdit && !isCompanyEdit) || pageIndex != 3) && (<h2>{_t(`step.${pageIndex}`, `Step ${pageIndex}`)}</h2>)}
      {(!isPendingCompanyEdit && !isCompanyEdit && pageIndex == 2) && (<p>{_t(`step2info`, `Step.info ${pageIndex}`)}</p>)}
      {pages[pageIndex]}
      <div className="formElement">
        <div className="indicators">
          {[...Array(maxPages).keys()].map(i => (<div className={i == pageIndex ? "active": ""} />))}
        </div>
      </div>
    </div>
  );
}

export default CompanyRegister;
