import { h } from 'preact';
import { useState } from 'preact/hooks';
import { route } from 'preact-router';
import style from './style.css';
import Cookie from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faDoorOpen, faPersonRunning } from '@fortawesome/free-solid-svg-icons';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Text, MarkupText } from 'preact-i18n/src';
import root from 'window-or-global';
import tld from '../../utils/tld';

const Header = () => {
  const [lang, setLangCookie] = useState(Cookie.get('lang'));

  return (
    <header className={`${style.header} noprint`}>
      <img className={style.logo} src="/assets/images/logo.jpeg" onClick={(e) => {
        let url = "/";
        let ori = root.location.pathname.slice(1);
        let x = ori.split("/");
        if(x[0] == "zamow") url = "/";
        if(x[0] == "superadmin") url = "/superadmin";
        if(x[1] == "user" || x[1] == "newmessage") url = `/${x[0]}`;
        if(x[1] == "manager") url = `/${x[0]}/${x[1]}`;
        if(x[0] && url == "/") url = `/${x[0]}`;
        if(x[0] == "pwdresetconfirmation") url = "/";
        route(url);
      }}/>
      <nav>
        <Popup closeOnDocumentClick on={["hover", "click"]}contentStyle={{width: 'auto'}} trigger={<div className={style.iconTrigger}><FontAwesomeIcon icon={faGlobe} className={"fa-2x"} /></div>} position={'left bottom'}>
          <div className={`${style.iconTriggerItem} ${lang == "pl" ? style.active : ""}`} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            Cookie.set("lang", "pl", {domain: `.${tld}`, expires: 365});
            root.location = root.location;
          }}><Text id="languages.PL">PL</Text></div>
          <div className={`${style.iconTriggerItem} ${lang == "de" ? style.active : ""}`} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            Cookie.set("lang", "de", {domain: `.${tld}`, expires: 365});
            root.location = root.location;
          }}><Text id="languages.DE">DE</Text></div>
        </Popup>
        {Cookie.get('suad_token') && (<div className={style.iconTrigger} onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          Cookie.remove('suad_token', {
            path: '/',
            domain: `.${tld}`,
          });
          root.location = root.location;
        }}><FontAwesomeIcon icon={faDoorOpen} className={"fa-2x"} /><MarkupText className={style.markup} id="secureLogout" /></div>)}
        {Cookie.get('comaad_token') && (<div className={style.iconTrigger} onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          Cookie.remove('comaad_token', {
            path: '/',
            domain: `.${tld}`,
          });
          root.location = root.location;
        }}><FontAwesomeIcon icon={faDoorOpen} className={"fa-2x"} /><MarkupText className={style.markup} id="secureLogout" /></div>)}
        {Cookie.get('cousad_token') && (<div className={style.iconTrigger} onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          Cookie.remove('cousad_token', {
            path: '/',
            domain: `.${tld}`,
          });
          root.location = root.location;
        }}><FontAwesomeIcon icon={faDoorOpen} className={"fa-2x"} /><MarkupText className={style.markup} id="secureLogout" /></div>)}
      </nav>
    </header>
  );
}

export default Header;
