import root from 'window-or-global';
import isDevEnv from './isDevEnv';;

let x = root.location.host || '';
if(!isDevEnv){
  x = x.split(".");
  x.splice(0,1);
  x = x.join('.');
}else{
  x = x.split(":");
  x = x[0];
}

export default x;
