import { h } from 'preact';
import style from './style.css';
import { getYear } from 'date-fns';
import { generateLocalization } from './../../utils/generateLocalization';

const [_t, _ph] = generateLocalization("footer");

const Footer = () => (
  <footer class={`${style.footer} noprint`}>
    <p>
      <a href="https://www.pibip.pl/faq" target="_blank">
        {_t("faq")}
      </a>
      <span> | </span>
      <a href="https://www.pibip.pl/contact" target="_blank">
        {_t("contact")}
      </a>
      <span> | </span>
      <a href="https://www.pibip.pl/regulamin" target="_blank">
        {_t("agb")}
      </a>
      <span> | </span>
      <a href="https://www.pibip.pl/polityka" target="_blank">
        {_t("ds")}
      </a>
    </p>
    <p>Instytut Compliance &copy;{ getYear(new Date()) }</p>
  </footer>
);

export default Footer;
